/*
 * (C) Copyright  2024 DigitalLab.  Confidential computer software.
 * Valid license from DigitalLab required for possession, use or copying.
 * Consistent with FAR 12.211 and 12.212, Commercial Computer Software,
 * Computer Software Documentation, and Technical Data for Commercial Items are
 * licensed to the U.S. Government under vendor's standard commercial license.
 */

import { ChatList } from './chat/ChatList';
import PropTypes from 'prop-types';
import {useEffect, useState} from "react";
import {jwtDecode} from "jwt-decode";
import {useNavigate} from "react-router-dom";

/**
 * Side menu component, this is where the chat list should be displayed.
 * @param chatList list of chats
 * @returns {JSX.Element} side menu
 * @constructor
 */
export const SideMenu = ({ chatList, isLoading, token, fetchMessages, setToken }) => {
    const [decodedToken, setDecodedToken] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (token !== "") {
            fetchMessages();
            const decoded = jwtDecode(token);
            setDecodedToken(decoded);
            console.log(decoded);
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
            console.log("Empty token: Side menu")
        }
    }, [token]);

    const handleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const logout = () => {
        setToken("");
        setIsLoggedIn(false);
        navigate('/home');
    }

  return (
   <>
        { isLoggedIn  ? <>
                <nav className={ isMenuOpen ? "side-menu" : "hide"}>
                    <ChatList chatsList={chatList} isLoading={isLoading} handleMenu={handleMenu}></ChatList>
                    {
                        decodedToken ?
                            <div>
                            <div className="name-wrapper">
                                <p>{decodedToken.name}</p>
                                <img src={decodedToken.picture} alt="profile" className="profile-picture"></img>
                            </div>
                            <p onClick={logout} className="logout-button">Logout</p>
                            </div>
                            : <p></p>
                    }
                </nav>
            <div className={isMenuOpen ? "hide"  : "menu-button" }>
                <button className="menu-button" onClick={handleMenu}><span className="material-symbols-outlined">menu</span></button>
            </div>
        </> : <></> }
   </>
  );
};

SideMenu.propTypes = {
  chatList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool,
    token: PropTypes.string,
    fetchMessages: PropTypes.func,
    setToken: PropTypes.func,
};
