/*
 * (C) Copyright  2024 DigitalLab.  Confidential computer software.
 * Valid license from DigitalLab required for possession, use or copying.
 * Consistent with FAR 12.211 and 12.212, Commercial Computer Software,
 * Computer Software Documentation, and Technical Data for Commercial Items are
 * licensed to the U.S. Government under vendor's standard commercial license.
 */

import PropTypes from "prop-types";
import "./HomeScreen.css";
import  AdDescription from "./ad_description.webp";
import AdCreadtion from "./ad_creation.webp";
import KeywordOptimization from "./keyword_optimization.webp";
import { GoogleLogin } from '@react-oauth/google';
import {Link, useNavigate} from "react-router-dom";

export const HomeScreen = ({setIsLoggedIn, setToken}) => {
    const navigate = useNavigate();
    const handleLogin = (token) => {
        setIsLoggedIn(true);
        setToken(token);
        navigate('/');
    }
    return (
        <div className="main-area-home">
            <nav className="top-menu-home">
                <div className="top-menu-home-content">
                    <h1 className="logo-home">PPCBot</h1>
                </div>
                <div className="top-menu-options">
                    <GoogleLogin
                        onSuccess={credentialResponse => {
                            console.log(credentialResponse);
                            handleLogin(credentialResponse.credential);
                        }}
                        onError={() => {
                            console.log('Login Failed');
                        }}
                    />
                </div>
            </nav>
            <section className="home-section header-home-section">
                <h2 className="title-home">Turn Ideas into Ads</h2>
                <p className="home-text">
                    <strong>Boost Your Sales with PPCBot: Simple, Effective Ad Creation Through Chat!</strong><br/>
                    No more complicated forms or guesswork—just chat with PPCBot, and we’ll help you craft engaging ads, recommend the best keywords, refine segmentations, and optimize your descriptions. Winning new customers has never been this easy!
                </p>
                <div className="login-with-google-wrapper">
                    <GoogleLogin
                        onSuccess={credentialResponse => {
                            console.log(credentialResponse);
                            handleLogin(credentialResponse.credential);
                        }}
                        onError={() => {
                            console.log('Login Failed');
                        }}
                    />
                </div>
            </section>
            <section className="sub-header-home-screen">
                <div className="sub-header-section">
                    <h2>Simple Ad Creation</h2>
                    <p>PPCBot allows you to create ads through an easy chat interface, eliminating the need for complicated forms.</p>
                    <img src={AdDescription} alt="Ad description"/>
                </div>
                <div className="sub-header-section">
                    <h2>Keyword Optimization</h2>
                    <p>PPCBot recommends the best keywords to maximize the effectiveness of your ads.</p>
                    <img src={AdCreadtion} alt="Ad creation"/>
                </div>
                <div className="sub-header-section">
                    <h2>Ad Description Optimization</h2>
                    <p>The bot helps optimize your ad descriptions for better engagement and conversions.</p>
                    <img src={KeywordOptimization} alt="Keyword optimization"/>
                </div>
            </section>
            <section className="home-section header-home-section">
                <h2 className="title-home">Get started easily with a personalized product tour</h2>
                <p className="home-text align-left">
                    An onboarding specialist is here to help you get started with confidence—it’s included with Standard and Premium plans.*
                </p>
            </section>
            <nav className="footer-home">
                <div className="top-menu-options">
                    <Link className="link" to="/privacy"><p>Privacy</p></Link>
                    <Link className="link" to="/privacy"><p>Term of use</p></Link>
                </div>
                <div className="top-menu-home-content">
                    <h1 className="logo-home">PPCBot 2024, All right reserved</h1>
                </div>
            </nav>
        </div>
    )
}

HomeScreen.propTypes = {
    setIsLoggedIn: PropTypes.func,
    setToken: PropTypes.func
}
