/*
 * (C) Copyright  2024 DigitalLab.  Confidential computer software.
 * Valid license from DigitalLab required for possession, use or copying.
 * Consistent with FAR 12.211 and 12.212, Commercial Computer Software,
 * Computer Software Documentation, and Technical Data for Commercial Items are
 * licensed to the U.S. Government under vendor's standard commercial license.
 */

import { TopMenuMainArea } from './TopMenuMainArea';
import { ChatSection } from './chat/ChatSection';
import { v4 as uuidv4 } from 'uuid';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { SideMenu } from './SideMenu';
import axios from 'axios';
import {Privacy} from "./chat/Privacy";
import {HomeScreen} from "./HomeScreen";
import {useState} from "react";
import {useGoogleLogin} from "@react-oauth/google";
import {jwtDecode} from "jwt-decode";

/**
 * Main area component, this is where the chatbot is displayed.
 * @returns {JSX.Element} main area
 * @constructor
 */
export const MainArea = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState("");
  const [chatList, setChatList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchMessages = async () => {
    try {
      const email = {"email": jwtDecode(token).email};
      const response = await axios.post('https://api.ppcbot.tech/chats/list', email);
      setChatList(response.data);
      setIsLoading(false);
      console.log(isLoggedIn)
    } catch (error) {
      console.error(error);
      setChatList([]);
    }
  };

  const new_uuid = uuidv4();
  console.log(new_uuid);
  // eslint-disable-next-line no-unused-vars
  const login = useGoogleLogin({
    onSuccess: codeResponse => {
      console.log("Response: " + codeResponse);
    },
    flow: 'auth-code',
  });

  const handleReload = () => {
    fetchMessages();
  };

  return (
      <div className="app">
        <Router>
          <SideMenu chatList={chatList} isLoading={isLoading} token={token} fetchMessages={fetchMessages} setToken={setToken} />
          <section className="main-area">
            <TopMenuMainArea></TopMenuMainArea>
            <Routes>
              <Route path="/" element={<ChatSection token={token} handleReload={handleReload} />} />
              <Route path="/c/:id" element={<ChatSection token={token} handleReload={handleReload} />} />
              <Route path="/settings" element={<h1>Settings</h1>} />
              <Route path="/home" element={<HomeScreen setIsLoggedIn={setIsLoggedIn} setToken={setToken}/>} />
              <Route path="/privacy" element={<Privacy />} />
            </Routes>
          </section>
        </Router>
        </div>
  );
};
